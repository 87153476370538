import React, { Component, useEffect } from 'react'
import './index.scss'


export default function Slider(props) {
    
    
    return (
        <div id='home'>
            <div className='container-slider container-fluid m-0 p-0' id="container-slider">
                <div className='img-logo animate__animated animate__zoomIn animate__slow'>
                    <img src={props.logo} alt='logo'/>
                   
                </div>
    
                <div className='container-fluid m-0 p-0 img-home'>
                    <img className='position-relative' src={props.imgURL} alt={props.alt} />
                </div>
                <div className='container-fluid m-0 p-0 bg-slider'></div>

                 <div className='m-0 p-0 slider-body '>
                        
                    <div className='thing m-0 p-0 '>
                        <div className="title-slider  container animate__animated animate__fadeInDown ">
                            <h1 className='caption' >{props.title} </h1> 
                        </div>
                        <div className='line bg-color' id="line-slide"></div>

                        <div className='description p-2 text-white animate__animated '>
                
                        <p className="caption-p animate__animated  animate__fadeInUp animate__slow"> {props.caption}</p>
                        </div>
                      

                    </div> 

                </div>


            </div> 

        </div>
    )
}