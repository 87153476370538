import React,{ useState } from 'react';
import './index.scss';

export default function scrollIcon(props){
    return(
        <div className='m-0 p-0 scrollIcon position-fixed hover p-0 m-0' id={props.id}>
        <a className='p-0 m-0'  href={props.href} >{props.icon}</a>

    </div>
    )
   

}