import React,{ useState } from 'react';
import './index.scss';

export default function WhatsappIcon(props){
    return(
        <div className='m-0 p-0 whatsappIcon position-fixed hover p-0 m-0'>
            <div className="red-dot"></div>
        <a className='p-0 m-0' href={"whatsapp://send?text=Hi!&phone=" + props.phone} ><i className="fa-brands fa-whatsapp"></i></a>

    </div>
    )
   

}