import React from 'react'
import './contact.scss'

const Contact = () => {
    return (
        <div id='Contact' className='py-4'>
            <div className='container'>
                <h2 className='text-center  mb-4'>  Contact <div className="underline"></div></h2>
                <div className='border p-3'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='item border p-3 text-center mb-4 m-lg-0' data-aos="fade-down">
                                <h6>Find US</h6>
                                <hr style={{ borderWidth: 2 }} />
                                <div className='contact-location'>
                                    <p className='m-0'>Second Floor Office 15 ( Maphuthaditshaba office Park)</p>
                                    <p className='m-0'>Burgersfort CBD</p>
                                    <p className='m-0'>Limpopo Province</p>
                                    <p className='m-0'>South Africa</p>
                                    <p className='m-0'>1150</p>
                                    <p className='m-0'>  Operating Hours: 08:15 - 16:30 Mon-Fri</p>
                                </div>
                                <hr />
                                <ul className='list-unstyled findus-info mt-3 text-align-left'>
                                    <li>
                                        <i className="fa-solid fa-envelope"></i><a href="mailto:info@medsmadiagane.co.za">info@medsmadiagane.co.za</a>
                                    </li>
                                    <li><i className="fa-solid fa-clock"></i> Operating Hours: 08:15 - 16:30 Mon-Fri</li>
                                </ul>
                                <hr/>
                                <ul className='list-unstyled contact-socials d-flex justify-content-center mt-4'>
                                    <li className='facebook '><a href="facebook"><i className="fa-brands fa-facebook-f"></i></a></li>
                                    <li className='whatsapp'><a href="whatsapp://send?text=Hi!&amp;phone=+27728108533"><i className="fa-brands fa-whatsapp"></i></a></li>
                                    <li className='twitter'><a href="twitter"><i className="fa-brands fa-twitter"></i></a></li>
                                    <li className='instagram'><a href="instagram"><i className="fa-brands fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <form action="madiaganeAPI.php" method='POST' className='border p-3  contact-form' data-aos="fade-up">
                                <div className='row '>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <input type="text" name="name" className="form-control" id="name" placeholder='Name:' required/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="mb-3">
                                            <input type="number" name= "phone" className="form-control" id="phone" placeholder='Phone:' required/>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="email" name="email" className="form-control" id="EmailAddress" placeholder='Email Address:' required/>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-floating">
                                            <textarea className="form-control" name="msg" placeholder="Message" id="floatingTextarea2" style={{ height: 200 }} defaultValue={""} required></textarea>
                                            
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <button type='submit' className='btn mt-3'>Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='mt-5 p-3 border'>
                    <iframe data-aos="zoom-in" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1812.8406678103265!2d30.325395158215073!3d-24.669094758621956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ec22d3a7f5bb117%3A0xc7672c9d07ba378a!2sFair%20Price%20Burgersfort%20Store!5e0!3m2!1sen!2seg!4v1659560186193!5m2!1sen!2seg" width={"100%"} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact