import React,{useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';



import Slider from '../Slider';

import img from '../../assets/img/SliderBackground.png'; 

import img1 from '../../assets/img/pexels-jeshootscom-834892.jpg';

import logo from '../../assets/img/logo.png'

import logo1  from '../../assets/img/logo1.jpg'

var indexSlide =0;

//This is to add remove classes from animated elements so that they can animate again when the state changes

function getSlide(list){
    return(
    <>
    <Slider title ={list.title} caption ={list.caption} imgURL={list.imgURL} alt={list.alt} logo={list.logo} />
    </>
    ) 
}

let mission = "Our business is to provide clients with profitable mining and engineering solutions that add value to their business and make them more competitive.";
let version = "Madiagane Engineering And Design Solutions strives to gain a deep understanding of our clients and their businesses to provide rational advice and creative solutions to challenges.";

function isElementVisible(el) {

    var rect     = el.getBoundingClientRect();
        var  vWidth   = window.innerWidth || document.documentElement.clientWidth;
         var vHeight  = window.innerHeight || document.documentElement.clientHeight;
         
 
     // Return false if it's not in the viewport || 
     //console.log(rect.top);
     if (  rect.top < 0 ){
       return false;
     }
       
     else{
       return true;
     }
 
     
  }

export default function Header(){
   
          
const Sliders = [
  {id:1,title :"Mission" ,caption:mission,imgURL:img1,alt:"backgroud img",logo:logo1 }, 
 {id:2,title :"Vision", caption:version,imgURL:img,alt:"backgroud img",logo:logo} 
]
    useEffect(() => {
        var el1 =  document.getElementById("header-bar");
        el1.classList.remove("none");
        el1.classList.add("in-viewport");
    
   
        document.getElementsByClassName("container-slider")[1].style.display = "none";
        document.getElementsByClassName("container-slider")[0].style.display = "block";

       const setint = setInterval(()=>{
        let el = document.getElementsByClassName("container-slider")[0];
       
        if(el.style.display !="block"){
          el = document.getElementsByClassName("container-slider")[1];
        }

        let icons = [document.getElementById("scroll1"),document.getElementById("scroll2")]
      
    if(! isElementVisible(el) ){
      
     el1.classList.remove("in-viewport");
   
     el1.classList.remove("none");

    el1.classList.add("out-viewport");
    el1.classList.add("animate__fadeInDown");
    icons[0].style.display = "none";
    icons[1].style.display = "block";
     }
     else{
      el1.classList.remove("out-viewport");
      el1.classList.remove("none");
      el1.classList.remove("animate__fadeInDown")
      el1.classList.add("in-viewport");
      icons[1].style.display = "none";
    icons[0].style.display = "block";
    
    }

        if(indexSlide<Sliders.length-1){
              indexSlide++;
              document.getElementsByClassName("container-slider")[0].style.display = "none";
                document.getElementsByClassName("container-slider")[indexSlide].style.display = "block";
              
              
            }
            else{
                 indexSlide =0;
                 document.getElementsByClassName("container-slider")[1].style.display = "none";
                 document.getElementsByClassName("container-slider")[indexSlide].style.display = "block";
                   
    
               }
      
           

        },12000);
    
          
        return () => clearInterval(setint);

    }
   
    ,[])
    
   
   

    
    return (
    
        <>
       {Sliders.map((Sliders) => <Slider title={Sliders.title} key={Sliders.id} imgURL = {Sliders.imgURL} text={Sliders.text} logo={Sliders.logo} caption={Sliders.caption} />)}
        </>
        
        
        
    )
}