import React, { useState } from 'react'
import ServiceSlider from './ServiceSlider'
import img from '../../assets/img/IMG-20211024-WA0019.jpg'
import plans from '../../assets/img/plans.png'
import details from '../../assets/img/housedetails.jpg'
import house from '../../assets/img/house.jpg'
import water from '../../assets/img/water.jfif'
import road from '../../assets/img/road.jfif'
import mechanics from '../../assets/img/mechanics.jpg'
import civil from '../../assets/img/civil.png'
import supply from '../../assets/img/supply.svg'

import './service.scss';

const Services = () => { 
    

    const [items, setitems] = useState([
        {id:1,title:"Architectural Design",
        img:plans,description:["Residentials buildings","Commercial buildings","Mine Layouts","Arial Views Designs"]},
        {id:2,
        title:"Draughting and Detailing",
        img:details,description:["Fabrication Drawings","Engineering Drawings","General arrangements"]},
           
            {id:3,title:"Mechanical Design",img:mechanics,description:["Conveyor belt Designs"]},
            {id:4,title:"Civil and structural Design",img:civil,description:["Steelwork Design","Conveyor Belt Structures Design","Design of Process Plant Steelwork","Dynamics Structures Design Services","Design of Platework such as Chutes or Bin ","Shed and Warehouse Designs"," Underground Dam Designs"]},
            {id:5,title:"Commercial Supply and Delivery",img:supply,description:["Gym and Sport clothing","Stationery","PPE"]}]);


            

    const [items1, setitems1] = useState([
        {id:1,title:"STRUCTURAL AND BUILDING",
        img:house,description:["Foundation", "Concrete Slabs & Column", "Retaining Walls", "Roof", "Gutter", "Portable Frames", "Beams", "Base Plates","Truss","HVAC & Electrical"]},
        {id:2,
        title:"ROAD &  STORMWATER",
        img:road,description:["Road construction and maintenance","Pavement","Stormwater"]},
           
            {id:3,title:"WATER CONSTRUCTION",img:water,description:["Bulk Water & Reticulation",
            "Bulk Wastewater & Sewer Networks","Water & Wastewater Treatment","Reservoirs","Water Towers"," Pump Station"]},
            ]);

    return (
        <>
        <div id='services' className='py-4 m-0 px-1 container-fluid'>
            <div className=' m-0 services-container'>
                <div className='services-body p-0 mt-3 ml-0 mr-0 text-center' >
                    <h2 className='text-center '> Design Services <div className='underline'></div></h2>
                    <ServiceSlider items={items}/>
                </div>
            </div>
        </div>

        <div id='services' className='py-4 m-0 px-1 container-fluid'>
        <div className=' m-0 services-container'>
            <div className='services-body p-0 mt-3 ml-0 mr-0 text-center' >
                <h2 className='text-center '> Construction Services <div className='underline'></div></h2>
                <ServiceSlider items={items1}/>
            </div>
        </div>
        </div>
</>
    )
}

export default Services