import Aos from 'aos';
import React, { useEffect, useState } from 'react';
import img from '../../assets/img/engineer.jpg';
import Button from '../Button';
import './about.scss';

const About = () => {

    const [readMore, setReadMore] = useState(false); //set readMore to be false by default

    //collapse the content 

    let readMore1 = `The team prides itself on its wealth of experience, renowned 
technical know-how and state-of-the-art technical solutions that are in great demand. The company retains people of exceptional quality and determined who uphold the highest standards of integrity and professionalism."
`;
    useEffect(() => {
        
       
        Aos.init({duration:1500});
    }, []);

    let scrool = ()=>{
        let url = window.location.href;
        url = url.split("#");
        url = url[0] + "#Contact";
        window.location.href = url;

    }
  

    return (
        <div id='about' className='py-4'>
            <div className='container'>
   
                <h2 className='text-center my-3 '  data-aos="fade-down">About us <div className='underline'></div></h2>
                <div className='row align-items-center'>
                    <div className='col-xl-6 '>
                        <div className='item ' data-aos="flip-up">
                            <img src={img} className="w-100 scale rounded mb-4 mb-xl-0 hover-scale transition-all" alt="" />
                        </div>
                    </div>
                    <div className='col-xl-6'>
                        <div className='item ' data-aos="fade-down">
                            <div className='captions  transition-all bg-image-card'>

                                <div className='text'>

                                    <p>
                                        Madiagane Engineering And Design Solutions (PTY) LTd was
                                        founded by a team of Professional engineers who play an
                                        active role in the design and development engineering environment and bring
                                        world-class standards to the industry. The team has a background in mining engineering,
                                        financial management, safety, health and environmental management and supply management
                                        and has extensive project management and execution experience. . . <span className='read-more' onClick={() => setReadMore(true)}>

                                            {!readMore && " Read more "}</span>
                                        {readMore && readMore1} {/* Return more content if read me is clicked */}

                                    </p>

                                </div>

                                <div className='conect-button'>
                                </div>
                                <Button text="connect with us" onClick={ scrool } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About