import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import underground from '../../assets/img/undergroundbelt.jpg'; 
import Gantry_Conveyor from '../../assets/img/Gangry.jpg'; 
import Workshop from '../../assets/img/workshop.jpg'; 
import Community_hall from '../../assets/img/hall.jpg';
import Laundry_Facility from '../../assets/img/laundry.png';
import Modern_Ablution from '../../assets/img/abution.jpg';
import Creche_building from '../../assets/img/creche.jpg'; 
import Double_storey from '../../assets/img/doublestorey.jpg';
import Training_centre from '../../assets/img/training centre.jpg';
import Plant_layout from '../../assets/img/plant_layout.jpg';
import Aerial_view from '../../assets/img/ariel.png';
import AS_built from '../../assets/img/asbuilt.jpg';
import Gym from '../../assets/img/gym.PNG';


import './works.scss'

const Work = () => {

    const [workContent, setworkContent] = useState([
        {
            id: 1,
            type: "Mechanical Design", projects: [
                {
                    id: 1,
                    title: "Underground Conveyor belt extension",
                    link: "#",
                    image: underground
                }, {
                    id: 2,
                    title: "Gantry Conveyor design",
                    link: "#",
                    image: Gantry_Conveyor
                }, {
                    id: 3,
                    title: "Workshop design",
                    link: "#",
                    image: Workshop
                }
            ]
        }, {
            id: 2,
            type: "Civil and Structural Design", projects: [
                {
                    id: 1,
                    title: "Community hall building",
                    link: "#",
                    image: Community_hall
                }, {
                    id: 2,
                    title: "Laundry Facility",
                    link: "#",
                    image: Laundry_Facility
                }, {
                    id: 3,
                    title: "Modern Ablution Facility",
                    link: "#",
                    image:Modern_Ablution
                }
            ]
        },
        {
            id: 3,
            type: "Architectural Design", projects: [
                {
                    id: 1,
                    title: "Creche building facility",
                    link: "#",
                    image: Creche_building
                }, {
                    id: 2,
                    title: "Double storey building office",
                    link: "#",
                    image: Double_storey
                }, {
                    id: 3,
                    title: "Training centre facility",
                    link: "#",
                    image: Training_centre
                }
            ]
        },
        {
            id: 4,
            type: "Draughting and Detailing", projects: [
                {
                    id: 1,
                    title: "Plant layout detailing",
                    link: "#",
                    image: Plant_layout
                }, {
                    id: 2,
                    title: "Aerial view mine layout ",
                    link: "#",
                    image: Aerial_view
                }, {
                    id: 3,
                    title: "AS-built conveyor drawings",
                    link: "#",
                    image: AS_built 
                }
            ]
        },
        {
            id: 5,
            type: "Commercial Supply and Delivery", projects: [
                {
                    id: 1,
                    title: "Gym and sports clothing",
                    link: "#",
                    image: Gym 
                }
            ]
        }
    ]);

    return (
        <div id='work' className='py-4'>
            <div className='container'>
                <h2 className='text-center text-center '>Our Work <div className='underline'></div></h2>
                <hr />
                <Tabs className="mt-4 " >
                    <div className='container-fluid m-0 px-0 pt-1 pb-0 position-sticky left-0 z-index-9 bg-white tbs-work'>
                    <TabList >
                        {
                            workContent.map((item, idx) => (

                                <Tab key ={ idx} >{item.type}</Tab>

                            ))
                        } </TabList>
                        </div>
                    {
                        workContent.map((item, idx) => (<TabPanel key ={ idx} className="px-3">
                            <h3>{idx + 1 }. {item.type} Projects</h3>

                            <div className='row py-4 border d-flex'>
                                {item.projects.map((item, idx) => (
                                    <div key={idx} className="col-xl-3 col-lg-6 col-sm-12 container-work" data-aos="fade-down">
                                        <div className='item position-relative mb-3 hover-zoom text-center'>
                                            <img src={item.image} alt={item.title} className="w-100 h-100" />
                                            <p className='project-item-title'>{item.title}</p>
                                            <a className='project-item-anchor' href={item.link}>VIEW PROJECT</a>
                                        </div>
                                    </div>
                                ))

                                }
                            </div>


                        </TabPanel>))
                    }

                </Tabs>
                
            </div>
        </div>
    )
}

export default Work