import React, { Component,useEffect } from "react";
import Slider from "react-slick";
import './serviceslider.scss'

const { innerWidth: width, innerHeight: height } = window;




function Swipe(props){
    //1s linear " +  props.idx  +" infinite
let ani = "swipe 1.5s linear  "+ props.idx  + "s infinite normal none running ";

    const style = {
        animation:ani
    }
    return(
        <>
         <span className="swipe ml-2" style={style}>
            <i className="fa fa-angle-right"></i></span>
        </>
    )
}

export default function ServiceSlider(props){
    var swipes = [{id:0},{id:0.1},{id:0.2},{id:0.3},{id:0.4},{id:0.5},{id:0.6},{id:0.7},
        {id:0.8},{id:0.9},{id:1},{id:1.1},{id:1.2},{id:1.3},{id:1.4}]
    
        useEffect(() => {
            // eslint-disable-next-line no-restricted-globals 
            var be = screen.width;
           
            if(be<400){
                
                let el = document.getElementsByClassName("slick-arrow");
                for(let i=0; i<el.length; i++){
                    el[i].style.display = "none";
                }
               
                //  document.getElementsByClassName("slick-arrow")[1].style.display = "none";
            }
              
             
            
           
        }, [])
        
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }

            ]
        };
        return (
            <div className="py-2  border border-1 px-1 px-lg-0 e m-0">

                <Slider {...settings}>
                    {
                        props.items?.map((item, idx) => (
                            <div className="service-item px-2" key={idx}>
                                <div className="container ml-0 mr-0 mb-2 p-0 text-left d-flex flex-direction-row"><span><b>{idx + 1}/{props.items.length}</b></span>
                                 
                                {
                                    swipes.map((swipes)=>(
                                        <Swipe key = {swipes.id} idx= {swipes.id} />
                                        ))
                                } 
                                <span className="swipe ml-2 delay-1-5" >Swipe</span>
                                 </div>
                                <h4 className="service-item-title rounded">{item.title}</h4>
                                <div data-aos="zoom-in" className="position-relative w-100 service-item-image" style={{ backgroundImage: `url(${item.img})` }}>
                                    <div className="service-overlay">
                                        <ul className="text-white text-start py-3  ">
                                            {
                                                item.description.map((item, idx) => (
                                                    <li key={idx}>{item}</li>
                                                ))
                                            }

                                        </ul>
                                    </div>
                                   
                                </div>
                            </div>
                        ))
                    }

                </Slider>
            </div>
        );
    
}