import React, { useRef } from 'react';
import './index.scss';
import marula from '../../assets/img/marula.jpeg';
import modikwa from '../../assets/img/modikwa.jpeg';
import samancor from '../../assets/img/samancor-logo-640.jpg';
import rivers from '../../assets/img/twoRivers.jpg';
import { useIsInViewport } from '../../components/ViewPort/index.js'


function Client(props) {
  return (
    <>
      <li className='p-0 ml-2 rounded'><a href={props.href} className='rounded p-0'> <img src={props.src} className="rounded" /></a></li>
    </>
  )

}


function ShowOurClients(props) {


}

export default function Our_clients() {

    
  const Clients = [
    { id: 1, href: 'https://www.implats.co.za/', src: marula },
    { id: 2, href: 'https://arm.co.za/arm-platinum/', src: modikwa },
    { id: 3, href: 'https://samancorcr.com/', src: samancor },
    { id: 4, href: 'https://arm.co.za/arm-platinum/', src: rivers }
  ];
      
     

  

    return (
      <>
      <div className='pl-2 our-client container' >
        <hr className="line" />
        <div className='container-fluid m-0 pl-2 d-flex flex-direction-row  our-client-container'>
          <div className='bob m-auto animate__animated animate__fadeInUp'> <p>Our Clients  <i className="arrow right"> &rarr;</i></p> </div>


          <div className='our-client-slide m-0 p-0 animate__animated animate__fadeInUp'>
            <ul>
              {Clients.map((Clients) => <Client key={Clients.id} href={Clients.href} src={Clients.src} />)}
            </ul>

          </div>


        </div>

        <hr className='line w-100' />
        </div>
      </>
    )
  
     

  
}
