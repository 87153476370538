import React from 'react';
import LinkButton from '../LinkButton';
import MenuBar from '../MenuBar';
import WhatsappIcon from '../../components/WhatsappIcon';
import ScrollIcon from '../../components/ScrollIcon';
import Button  from '../Button';
import './index.scss';
import { Outlet, Link } from "react-router-dom";


export default function Layout(){

    
    const Links = [
        {id: 1, text: 'Home',link:"home"},
        {id: 2, text: 'Services',link:"#services"},
        {id: 3, text: 'About Us',link:"#about"},
        {id: 4, text: 'Projects',link:"#work"},
        {id: 5, text: 'Contact Us',link:"#Contact"}
      ];

    let icon = ()=>{
        return(
            <>
             <i className='fa fa-angle-down'></i>
            </>
           
        )
    }
    let icon1 = ()=>{
        return(
            <>
             <i className='fa fa-angle-up'></i>
            </>
           
        )
    }

    let scrool = ()=>{
        let url = window.location.href;
        url = url.split("#");
        url = url[0] + "#Contact";
        window.location.href = url;

    }
  
    return (
        <>
         <div className="nav container-fluid animate__animated m-0 p-1 w-100 none" id="header-bar">
            <div className="title container-fluid "> <h3>MADIAGANE  ENGINEERING</h3>  </div>
            <div className='w-auto m-0 p-0 nav-bar-items d-flex flex-direction-row'>
            {Links.map((Links) => <LinkButton key={Links.id} text={Links.text} href={Links.link} />)}
            </div>
            <MenuBar />
        </div>

        <WhatsappIcon phone="+27729019636" />
<div className='button-slider animate__animated animate__fadeInUp animate__slow'>
                        <Button text="Contact Us Now!" icon="fa-arrow-right" onClick={scrool} />
                       </div>
<ScrollIcon href ="#footer" id="scroll1" icon={icon()}/>
<ScrollIcon href="#home" id="scroll2" icon={icon1()}/>
<Outlet />
        </>
        
    )
}