import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Our_clients from '../Our_clients'

import Services from '../Services/Services';
import About from '../About/About';
import Work from '../Our Work/Work';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';






export default function () {


    return (
        <>
            <div className="m-0 p-0 body-container">

                <div className="our-clients mt-2 ml-0 mr-0 p-0 container-fluid" >

                    <Our_clients />

                </div>


 
                <About />
                <Services />


                <Work /> 
                <div className='container'>
                <hr />
                </div>
              
                <Contact/>
                <Footer/> 
            </div>
        </>
    )
}