
import './App.css';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './common/styles/index.scss'

import Layout from './containers/Layout'
import Home from './containers/Home'
import NoPage from './containers/NoPage'
import Body from './containers/Body';
import Fail from './containers/Fail';
import Sucess from './containers/Sucess';
{/* <>
<Header />
<Body />

</> */}
function App() {
  return (
  
  <Router>
    <Routes>
        <Route path="/" element={<Layout/>}>

          <Route index element={<Home />} />
          <Route path ="home" element={<Home />} />
          <Route path ="fail" element={<Fail />} />
          <Route path ="success" element={<Sucess />} />
          <Route path="*" element={<NoPage />} />
        
        </Route>

        

        
    </Routes>
  </Router>
   
  
  );
  
}

export default App;
