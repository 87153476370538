import React from 'react';
import './footer.scss';
import img from '../../assets/img/logo1_meds3.png';

const Footer = () => {
    return (
        <div id='footer' className='py-3 position-relative'>
          
            <div className='container position-relative'>
                <div className="h4 color-theme text-center">Social links <span className="underline"></span></div> 
                <ul className='list-unstyled d-flex justify-content-center'>
                    <li><a href="facebook"><i className='fab fa-facebook-f fa-fw'></i></a></li>
                    <li><a href="twitter"><i className='fab fa-twitter fa-fw'></i></a></li>
                    <li><a href="whatsapp"><i className='fab fa-whatsapp fa-fw'></i></a></li>
                    <li><a href="phone"><i className='fas fa-phone fa-fw'></i></a></li>
                </ul>
                <div className="h4 color-theme text-center">Quick Links <span className="underline"></span></div> 
                <ul className='list-unstyled d-flex justify-content-center footer-navbar'>
                    <li><a href="#home">Home</a></li>   
                    <li><a href="#work">Projects</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#Contact">Contact Us</a></li>
                </ul>
                <div className='d-flex justify-content-center subscribe mt-4'>
                    <div>
                        <i className='fas fa-envelope'></i>
                    </div>
                    <div>
                        <input type="text" placeholder='Enter your email here...' name='Subscribeemail' />
                    </div>
                    <div>
                        <a href="Subscribe">Subscribe</a>
                    </div>
                </div>
                <p className='copyright text-center mt-3'>Copyright © 2022 All rights reserved - Madiagane Engineering </p>
                <hr className="bg-white border border-white" />
                <p className='copyright text-center mt-3'>Developed by <a href="https://www.weball.co.za">Weball Technologies</a> </p>
            </div>
            <div className='position-absolute  footer-end'>
                <img src={img} alt="" />
            </div>
        </div>
    )
}

export default Footer